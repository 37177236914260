<template lang="pug">
    section.columns(:class="[ classColunas ]", :style="style")
        .column(v-for="item, itemIndex in colunas")
            component(:is="item.component", :contents="item.contents", v-for="item, index in mappedComponents(itemIndex)", :key="`${item.type}-${index}`")
</template>

<script>
import { props, textComponents as components, textDictionary } from '@/mixins/component'

export default {
    name: "section-colunas",
    props,
    components,
    computed: {
        colunas() {
            return this.contents.content
        },
        classColunas() {
            return `columns-${this.colunas.length}`
        },
		style() {
			return {
				gridTemplateColumns: this.colunas.map(coluna => `${coluna.size}%`).join(` `),
                alignItems: `center`
			}
		}
    },
    methods: {
        mappedComponents(index) {
            return this.colunas[index].content.map(item => {
                return { component: this.$options.components[textDictionary[item.type]], contents: item, type: item.type }
            })
        }
    },
}
</script>

<style lang="stylus" scoped src="./Colunas.styl"></style>
